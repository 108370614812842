<template>
  <component
    :is="loaderComp"
    :selectedColor="selectedLoaderColor"
    :getCustomLoaderImage="customLoaderImage"
  ></component>
</template>

<script>
export default {
  data() {
    return {
      loaderComp: null,
      selectedLoader: "DefaultLoader",
      selectedLoaderColor: "#000000",
      customLoaderImage: "",
    };
  },

  watch: {
    selectedLoader: {
      immediate: true,
      handler(newVal) {
        this.loadComponent(newVal);
      },
    },
  },

  created() {
    this.initializeFromQuery();
  },

  methods: {
    async loadComponent(loaderName) {
      try {
        const componentConfig = await import(`@/components/LoaderComps/${loaderName}.vue`);
        this.loaderComp = componentConfig.default;
      } catch (err) {
        this.$toasted.show("Error Occured while initializing loader", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
      }
    },

    initializeFromQuery() {
      const { selectedLoader, selectedLoaderColor, customLoaderImage } = this.$route.query;

      if (selectedLoader) {
        this.selectedLoader = selectedLoader;
        this.selectedLoaderColor = selectedLoaderColor || this.selectedLoaderColor;

        if (selectedLoader === "CustomQuizLoader" && customLoaderImage) {
          this.customLoaderImage = customLoaderImage;
        }
      }
    },
  },
};
</script>

<style></style>
